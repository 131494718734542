<template>
  <div>
    <div class="open">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="订阅开关">
          <el-select
            v-model="form.pushType"
            placeholder="请选择"
            :popper-append-to-body="false"
            @change="switchBtn(form.pushType)"
          >
            <el-option
              v-for="item in optionsCus"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              clearable
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            class="submitBtn1"
            @click="subBao"
            v-if="isShow"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="openMain" v-if="form.pushType == '2'">
      <el-form ref="formList" :model="formList">
        <el-form-item label="推送地址" label-width="100px">
          <el-input v-model="formList.pushUrl"></el-input>
          <div>
            <el-button type="primary" class="submitBtn" @click="testSubmit"
              >测试</el-button
            >
            <el-button type="primary" class="submitBtn" @click="submitBtns"
              >保存</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="推送参数说明" label-width="150px">
          <div>接收请使用参数名：data( 字符串类型)</div>
        </el-form-item>
        <el-form-item label="推送数据格式" label-width="150px">
          <div style="line-height: 25px">
            { <br />
            "alarmNo":"1668483195287891",//告警编号<br />
            "alarmType":"8",//告警类型<br />
            "imei":"864857060000068",//告警设备IMEI<br />
            "userNo":"1002001",//设备绑定用户编号<br />
            "name":"一束梨",//用户名称<br />
            "sex":"1",//性别 <br />
            "idcard":"511322****1817",//身份证号<br />
            "phone":"134****1330",//联系电话<br />
            "address":"3号楼2单元1011",//住址（门牌号）<br />
            "alarmTime":"2022-11-1511:11:02",//报警时间 <br />
            "value":"160",//健康数据告警时上传数据值<br />
            "description":null //健康数据告警时描述说明<br />
            "valueType":"",
            //健康数据类型(1:血压,2:血糖,3:体温,4:心率,5:血氧)<br />}
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="openMain" v-if="form.pushType == '1'">
      <el-form ref="formList" :model="formList">
        <el-form-item label="数据查询接口" label-width="110px" class="formItem">
          <!-- <el-input v-model="formList.subscribeUrl" :disabled="true"></el-input> -->
          <div>{{ formList.subscribeUrl }}</div>
        </el-form-item>
        <el-form-item label="请求方式" label-width="110px" class="formItem">
          <div>{{ formList.params }}</div>
        </el-form-item>
        <el-form-item label="请求参数" label-width="110px" class="formItem">
          <div>参考API接口文档</div>
        </el-form-item>
        <el-form-item label="返回数据格式" label-width="150px" class="formItem">
          <div style="line-height: 25px; white-space: pre-line">
            { <br />"msg":"success", <br />"code":"200", <br />"result":[
            <br /><span v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"></span>
            {<br />
            "alarmNo":"1668483195287891",//告警编号<br />
            "alarmType":"8",//告警类型<br />
            "imei":"864857060000068",//告警设备IMEI<br />
            "userNo":"1002001",//设备绑定用户编号<br />
            "name":"一束梨",//用户名称<br />
            "sex":"1",//性别 <br />
            "idcard":"511322****1817",//身份证号<br />
            "phone":"134****1330",//联系电话<br />
            "address":"3号楼2单元1011",//住址（门牌号）<br />
            "alarmTime":"2022-11-1511:11:02",//报警时间 <br />
            "value":"160",//健康数据告警时上传数据值<br />
            "description":null //健康数据告警时描述说明<br />
            "valueType":"",
            //健康数据类型(1:血压,2:血糖,3:体温,4:心率,5:血氧)<br />
            <span
              v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"
            ></span
            >}<br />
            <span v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0'"></span>] <br />}
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="openMain" v-if="form.pushType == '3'">
      <el-form ref="formOne" :model="formOne">
        <el-form-item label="服务器地址" label-width="110px" class="formItem">
          <div>参考API接口文档</div>
        </el-form-item>
        <el-form-item label="监听用户名" label-width="110px" class="formItem">
          <div>你的apiKey</div>
        </el-form-item>
        <el-form-item
          label="监听topic队列"
          label-width="120px"
          class="formItem"
        >
          <div>server/api/{你的apiId}</div>
        </el-form-item>
        <el-form-item label="消息数据格式" label-width="150px" class="formItem">
          <div style="line-height: 25px">
            { <br />
            "alarmNo":"1668483195287891",//告警编号<br />
            "alarmType":"8",//告警类型<br />
            "imei":"864857060000068",//告警设备IMEI<br />
            "userNo":"1002001",//设备绑定用户编号<br />
            "name":"一束梨",//用户名称<br />
            "sex":"1",//性别 <br />
            "idcard":"511322****1817",//身份证号<br />
            "phone":"134****1330",//联系电话<br />
            "address":"3号楼2单元1011",//住址（门牌号）<br />
            "alarmTime":"2022-11-1511:11:02",//报警时间 <br />
            "value":"160",//健康数据告警时上传数据值<br />
            "description":null //健康数据告警时描述说明<br />
            "valueType":"",
            //健康数据类型(1:血压,2:血糖,3:体温,4:心率,5:血氧)<br />}
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      isShow: true,
      inputOne: "",
      form: {
        pushType: "1",
      },
      formList: {
        subscribeUrl: "http://testapi.careld.cn/alarm/api/getAlarm",
        params: "POST",
        pushUrl: "",
      },
      optionsCus: [],
      formOne: {},
    };
  },
  mounted() {},
  created() {
    this.getSubscribe();
    this.serviceTs();
  },
  destroyed() {},
  methods: {
    subBao() {
      this.$confirm("是否保存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShan
            .updatePushType({
              pushUrl: "",
              pushType: this.form.pushType,
            })
            .then((res) => {
              // console.log("400中心：", res.data);
              if (res.data.code == 200) {
                console.log(res.data, "查询测试结果");
                this.$message({
                  message: "保存成功！",
                  type: "success",
                });
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    serviceTs() {
      apiShan.dict({ code: "apipushtype" }).then((res) => {
        console.log("下拉：", res.data);
        if (res.data.code === 200) {
          this.optionsCus = res.data.result;
        }
      });
    },
    //开关
    switchBtn(item) {
      console.log("选择的是：", item);
      if (item == "1") {
        this.getSubshut();
        this.isShow = true;
      } else if (item == "2") {
        // this.getSubscribe();
        this.isShow = false;
      } else if (item == "3") {
        this.isShow = true;
      }
      console.log(item, "开关");
    },
    //获取开查询订阅数据
    getSubscribe() {
      apiShan.getUserInfo().then((res) => {
        // console.log("400中心：", res.data);
        if (res.data.code == 200) {
          console.log(res.data, "查询开订阅数据");
          this.form.pushType = res.data.result.pushType;
          if (res.data.result.pushType == "2") {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.formList.pushUrl = res.data.result.pushUrl;
          // this.formList = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取关查询订阅数据
    getSubshut() {
      this.formList.subscribeUrl =
        "http://testapi.careld.cn/alarm/api/getAlarm";
      this.formList.params = "POST";
    },
    //测试
    testSubmit() {
      apiShan.testPushUrl({ pushUrl: this.formList.pushUrl }).then((res) => {
        // console.log("400中心：", res.data);
        if (res.data.code == 200) {
          console.log(res.data, "查询测试结果");
          this.$message({
            message: res.data.result,
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },
    //保存
    submitBtns() {
      this.$confirm("是否保存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShan
            .testPushUrl({ pushUrl: this.formList.pushUrl })
            .then((res) => {
              // console.log("400中心：", res.data);
              if (res.data.code == 200) {
                console.log(res.data, "查询测试结果");
                apiShan
                  .updatePushType({
                    pushUrl: this.formList.pushUrl,
                    pushType: this.form.pushType,
                  })
                  .then((res) => {
                    // console.log("400中心：", res.data);
                    if (res.data.code == 200) {
                      console.log(res.data, "查询测试结果");
                      this.$message({
                        message: "保存成功！",
                        type: "success",
                      });
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  });
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.el-menu-vertical-demo {
  height: 100%;
}
.open {
  width: 96%;
  margin: 0 auto;
}
/deep/.el-form-item__label {
  font-size: 16px;
  text-align: left;
}
.openMain {
  width: 96%;
  margin: 0 auto;
}
/deep/.el-form-item {
  width: 55%;
  margin-bottom: 50px;
}
.submitBtn {
  padding: 9px 40px;
  font-size: 14px;
  margin-top: 4%;
}
.submitBtn1 {
  padding: 9px 40px;
  font-size: 14px;
  // margin-top: 4%;
  margin-left: 20px;
}
.formItem {
  margin-bottom: 20px;
}
/deep/ .el-select-dropdown__list {
  list-style: none;
  padding: 6px 10px !important;
  margin: 0;
  box-sizing: border-box;
}
</style>